<template>
  <div class="error-card">
    <div class="error-card-icon">
      <iconic :name="icon || 'exclamation_triangle'" />
    </div>
    <div class="error-card-message" v-html="message"></div>
    <div class="error-card-options"></div>
  </div>
</template>

<script>
export default {
  props: ["message", "icon", "options"],
};
</script>

<style lang="scss" scoped>
.error-card {
  position: relative;
  margin: auto;
  text-align: center;
  &-icon {
    font-size: 300%;
    color: red;
  }
  &-message {
    font-size: 120%;
    opacity: 0.8;
    font-weight: 500;
  }
  @include screen_tablet() {
    &-icon {
      font-size: 500%;
    }
    &-message {
      font-size: 150%;
    }
  }
}
</style>
